import * as styles from './index.module.scss'

import { navigate } from 'gatsby'
import React, { useState } from "react"
import { isLoggedIn } from '../../services/auth'

import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import AuthenticationForm from '../../components/authentication/form'
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const REQEUST_RESET_MAIL_MUTATION = gql`
  mutation RequestPasswordReset($login: String!){
    requestPasswordReset(input: { login: $login}) {
      success
    }
  }
`

const Login = ({ data }) => {
  if (isLoggedIn()) {
    navigate('/presse')
  }

  const [login, setLogin] = useState('')
  const [success, setSuccess] = useState(false)

  const [signin, { data: response, loading, error }] = useMutation(REQEUST_RESET_MAIL_MUTATION, {
    variables: { login },
    onCompleted: (data) => {
      if (data.requestPasswordReset.success) {
        setSuccess(true)
      }
    }
  })

  const submitForm = (e) => {
    e.preventDefault()
    signin()
  }

  if (success) {
    return (
      <>
        <Seo title="Passwort vergessen" />
        <Layout>
          <AuthenticationForm title='Passwort vergessen'>
            <p style={{ textAlign: 'center' }}>Wir haben ihnen eine E-Mail an <b>{login}</b> zum wiederherstellen des Passworts gesendet.<br />Bitte klicken Sie auf den darin enthaltenen Link.</p>
          </AuthenticationForm>
        </Layout>
      </>
    )
  }

  return (
    <>
      <Seo title="Passwort vergessen" />
      <Layout>
        <AuthenticationForm title='Passwort vergessen'>
          <form onSubmit={submitForm} className={styles.form} data-loading={loading}>
            <div className={styles.errors}>
              {(error && error.message.replace('GraphQL error: ', ''))}
            </div>
            <div className={styles.fields}>
              <input className={styles.field} type="text" placeholder="Benutzername oder E-Mail-Adresse" value={login} onChange={(e) => setLogin(e.target.value)} />
              <button className={styles.button} type="submit">Link anfordern</button>
            </div>
          </form>
        </AuthenticationForm>
      </Layout>
    </>
  )
}

export default Login
